import Gallery from "./Gallery";


const Portraits = ({ media, active }) => {

    return (
        <>
            <Gallery items={media} />
        </>
    )
}

export default Portraits;