import { useEffect, useRef, useState } from "react";
import LazyLoad from 'react-lazy-load';
import { toggleFullscreen } from "./MediaFullscreen";

const Gallery = ({ items }) => {

    const Image2 = ({ src, w, h, onLoad }) => {
        return (
            <img onLoad={onLoad} className="media-src" src={"/media/thumbnails/" + src + ".jpg"} /*width={w} height={h}*/ />
        )
    }

    const Video2 = ({ src, w, h, thumb = 0, onLoad }) => {

        const ref = useRef();
        const thumbnail = useRef();
        const [playable, setPlayable] = useState(false);
        const check = useRef();

        const enter = () => {
            //ref.current.currentTime = 0;
            //ref.current.play();
            if (ref.current.src === "") {
                ref.current.src = "/media/projects/" + src + ".mp4";
            }

            let interval;
            if (playable.current === false) {
                interval = setInterval(() => {
                    if (playable.current === true) {
                        clearInterval(interval);
                        enter();
                    }
                },)
            } else {
                check.current = setTimeout(() => {
                    thumbnail.current.style.opacity = 0;
                    ref.current.play();
                }, 500);
            }


        }

        const leave = () => {
            ref.current.play().then(() => { ref.current.pause(); ref.current.currentTime = 0 });
            thumbnail.current.style.zIndex = 100;
        }

        const leaveThumb = () => {
            clearTimeout(check.current);
            console.log(check.current);
            thumbnail.current.style.opacity = 100;
            ref.current.play().then(() => { ref.current.pause(); ref.current.currentTime = 0 });
        }

        const load = () => {
            onLoad();
            ref.current.classList.add("pp");

        }

        useEffect(() => {
            //ref.current.addEventListener("canplay", load);
        }, []);


        return (
            <>
                <img className="media-src" onLoad={load} onMouseEnter={enter} onMouseLeave={leaveThumb} ref={thumbnail} src={"/media/thumbnails/" + src + ".jpg"} style={{ "zIndex": 10, "opacity": 100, "transition": ".2s opacity"}}></img>
                <video className="media-src" onCanPlay={() => setPlayable(true)} ref={ref} muted={true} loop={true} width={w} height={h}>
                    <source src="" type="video/mp4"></source>
                </video>
            </>
        )
    }

    const Media = ({ type = "image", w = 300, h = 200, title = "", src = "", thumb = null }) => {

        const ref = useRef();
        const loader = useRef();

        const load = () => {
            setTimeout(() => {
                if (ref.current) {
                    ref.current.classList.add("loaded");
                    loader.current.style.display = "none";
                }
            }, 10)
        }

        return (
            <>
                <div ref={loader} className="loader"></div>
                <LazyLoad height={h} width={w} offset={500}>
                    <div className="media" onClick={() => toggleFullscreen(true, type, src)}>
                        <div ref={ref} className="media-container">
                            {type === "video" ? <Video2 src={src} w={w} h={h} onLoad={load} thumb={thumb} /> : <Image2 src={src} w={w} h={h} onLoad={load} />}
                        </div>
                        <p className="media-title">{title}</p>
                    </div>
                </LazyLoad>
            </>
        )
    }

    const renderMedia = () => {


        let col_left = [];
        let col_right = [];
        for (let i = 0; i < items.length; i++) {
            let col = i % 2 === 0 ? col_left : col_right;
            col.push(<Media type={items[i].type} title={items[i].title} src={items[i].src} w={items[i].w} h={items[i].h} thumb={items[i].thumb} />);
        }

        return (
            <>
                <div className="gallery-col">
                    {col_left}
                </div>
                <div className="gallery-col">
                    {col_right}
                </div>
            </>
        )
    }

    return (
        <>
            <div className="gallery">
                {renderMedia()}
            </div>
        </>
    )
}

export default Gallery;