import Gallery from "./Gallery";



const Projects = ({ media }) => {

    return (
        <>
            <Gallery items={media} />
        </>
    )
}

export default Projects;